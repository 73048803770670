import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import MainPage from "./areas/Start/MainPage";
import Nav from "./areas/Nav";
import Checkout from "./areas/Checkout/Checkout";
import Order from "./areas/Order/Order";
import Terms from "./areas/Checkout/Terms";
import Regulamin from "./areas/Regulamin";
import Rodo from "./areas/Rodo";

export const ROUTER_HOME = {
    MainPage: "/Start",
    About: "/OPoradniku",
    Authors: "/OAutorach",
    Preview: "/Podglad",
    BuyNow: "/Zamowienie",
    OrderDetails: "/ZamowienieDetale",
    Payment: "/ZamowieniePlatnosc"
};

export const ROUTES = {
    Start: {
        Base: "/Start",
        About: "/Start#O-Poradniku",
        Authors: "/Start#O-Autorach",
        Preview: "/Start#Podgląd"
    },
    Checkout: "/Koszyk",
    Order: "/Zamowienie",
    Terms: "/Regulamin",
    Rodo: "/Rodo"
};

const App: React.FC = () => {
    if (isIE()) {
        return (
            <div className="explorer">
                <div className="explorer-box">
                    <div className="explorer__image">
                        <img src="./assets/LIO-Logos-SE.png" className="explorer__image-photo" />
                    </div>
                    <div className="explorer__text">Strona nie obsługuje Internet Explorer. < br/>
                    Proszę użyć innej przeglądarki internetowej.</div>
                </div>
            </div>
        );
    }

    return (
        <BrowserRouter>
            <div className="router-home">
                <Nav />
                <Switch>
                    <Route path={ROUTES.Start.Base} component={MainPage} />
                    <Route path={ROUTES.Checkout} component={Checkout} />
                    <Route path={ROUTES.Order} component={Order} />
                    <Route path={ROUTES.Terms} component={Regulamin} />
                    <Route path={ROUTES.Rodo} component={Rodo} />
                    {/* <Route path={ROUTER_HOME.BuyNow} component={BuyNow} />
                    <Route path={ROUTER_HOME.OrderDetails} component={OrderDetails} />
                    <Route path={ROUTER_HOME.Payment} component={Payment} /> */}
                    <Redirect path="*" to={ROUTES.Start.Base} />
                </Switch>
            </div>
        </BrowserRouter>
    );
};

export default App;

function isIE() {
    const ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
}
