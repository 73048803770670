import React from "react";
import FieldWrapper, { FieldWrapperProps } from "./FieldWrapper";
import { FieldBaseProps } from "./FieldBase";

import "./TextField.scss";

const TextField: React.FC<FieldBaseProps<string> & FieldWrapperProps> = ({
    value,
    onChange,
    disabled,
    title,
    description,
    errors,
    onBlur,
    onFocus,
    fieldBlur,
    fieldFocus,
}) => {
    return (
        <FieldWrapper title={title} description={description} errors={errors}>
            <input
                className="text-field__input"
                type="text"
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                onFocus={() => handleFocus()}
                onBlur={() => handleBlur()}
                disabled={!!disabled}
            />
        </FieldWrapper>
    );

    function handleChange(value: string) {
        onChange(() => value);
    }

    function handleFocus() {
        if (fieldFocus) fieldFocus();
        if (onFocus) onFocus();
    }

    function handleBlur() {
        if (fieldBlur) fieldBlur();
        if (onBlur) onBlur();
    }
};

export default TextField;
