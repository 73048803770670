import { CustomerType } from "@schneider-ecommerce/utils-generic";
import React from "react";
import { Address, Customer } from "./Checkout.types";

import "./SummaryCustomerAndAddress.scss";

interface SummaryCustomerAndAddressProps {
    customerType: CustomerType;
    customer: Customer;
    address: Address;
    goBack?: () => void;
}

const SummaryCustomerAndAddress: React.FC<SummaryCustomerAndAddressProps> = ({ customerType, customer, address, goBack }) => {
    return (
        <div className="summary-customer-and-address">
            <div className="summary-customer-and-address__title">Dane zamawiającego</div>
            <div className="summary-customer-and-address__section">
                <div className="summary-customer-and-address__section-content">
                    <div className="summary-customer-and-address__section-field full">
                        <div className="summary-customer-and-address__section-field-label">Typ klienta</div>
                        <div className="summary-customer-and-address__section-field-value">
                            {customerType === 1 ? "Osoba Prywatna" : "Firma"}
                        </div>
                    </div>
                    {[
                        [customerType === 1 ? "Imię i Nazwisko" : "Nazwa Firmy", customer.name],
                        ["Email", customer.email],
                        ["Telefon", customer.phone],
                        ...(customerType === 2 ? [["NIP", customer.taxId]] : [])
                    ].map((item, index) => (
                        <div className="summary-customer-and-address__section-field" key={index}>
                            <div className="summary-customer-and-address__section-field-label">{item[0]}</div>
                            <div className="summary-customer-and-address__section-field-value">{item[1]}</div>
                        </div>
                    ))}
                </div>
                {goBack ? (
                    <div className="summary-customer-and-address__button" onClick={() => goBack()}>
                        Edytuj dane
                    </div>
                ) : null}
            </div>
            <div className="summary-customer-and-address__title">Dane do wysyłki</div>
            <div className="summary-customer-and-address__section">
                <div className="summary-customer-and-address__section-content">
                    <div className="summary-customer-and-address__section-field full">
                        <div className="summary-customer-and-address__section-field-label">Odbiorca</div>
                        <div className="summary-customer-and-address__section-field-value">{address.receipient}</div>
                    </div>
                    {[
                        ["Ulica", address.street],
                        ["Budynek", address.building],
                        ["Kod Pocztowy", address.zipCode],
                        ["Miasto", address.city]
                    ].map((item, index) => (
                        <div className="summary-customer-and-address__section-field" key={index}>
                            <div className="summary-customer-and-address__section-field-label">{item[0]}</div>
                            <div className="summary-customer-and-address__section-field-value">{item[1]}</div>
                        </div>
                    ))}
                    {address.comment.length > 0 ? (
                        <div className="summary-customer-and-address__section-field full">
                            <div className="summary-customer-and-address__section-field-label">Komentarz do kuriera</div>
                            <div className="summary-customer-and-address__section-field-value">{address.comment}</div>
                        </div>
                    ) : null}
                </div>
                {goBack ? (
                    <div className="summary-customer-and-address__button" onClick={() => goBack()}>
                        Edytuj dane
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default SummaryCustomerAndAddress;
