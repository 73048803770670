import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import smoothscroll from "smoothscroll-polyfill";

import "./scss/index.scss";

import App from "./App";

declare global {
    const APP_CONFIG: {
        API_URL: string;
        ENV: "dev" | "prod";
    };
}

smoothscroll.polyfill();

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
