import { ValidateEmail, ValidateNotEmpty, ValidateTaxNumber, ValidateTelephone, ValidateZipCode } from "@schneider-ecommerce/utils-generic";
import { FormError, FormValidateFunction, fieldNameIs } from "../../form/Form";
import { Address, Customer } from "./Checkout.types";

export const validateFormCustomer: FormValidateFunction<Customer> = async (form, fieldName, isEditing, customProps) => {
    const errors: Array<FormError<Customer>> = [];

    if (fieldNameIs(fieldName, "email")) {
        if (!ValidateEmail(form.email)) {
            errors.push({ fieldName: "email", id: "InvalidEmail", error: "Niepoprawny adres e-mail" });
        }
    }

    if (fieldNameIs(fieldName, "phone")) {
        if (!ValidateTelephone(form.phone)) {
            errors.push({ fieldName: "phone", id: "InvalidPhone", error: "Niepoprawny numer telefonu" });
        }
    }

    if (fieldNameIs(fieldName, "name")) {
        if (!ValidateNotEmpty(form.name)) {
            errors.push({ fieldName: "name", id: "NotEmpty", error: "Pole nie może być puste" });
        }
    }

    if (fieldNameIs(fieldName, "taxId")) {
        if (customProps.customerType === 2) {
            if (!ValidateTaxNumber(form.taxId)) {
                errors.push({ fieldName: "taxId", id: "InvalidTaxNumber", error: "Niepoprawny numer NIP" });
            }
        }
    }

    return {
        errors: errors
    };
};

export const validateFormAddress: FormValidateFunction<Address> = async (form, fieldName, isEditing, customProps) => {
    const errors: Array<FormError<Address>> = [];

    if (fieldNameIs(fieldName, "receipient")) {
        if (!ValidateNotEmpty(form.receipient)) {
            errors.push({ fieldName: "receipient", id: "NotEmpty", error: "Pole nie może być puste" });
        }
    }

    if (fieldNameIs(fieldName, "street")) {
        if (!ValidateNotEmpty(form.street)) {
            errors.push({ fieldName: "street", id: "NotEmpty", error: "Pole nie może być puste" });
        }
    }

    if (fieldNameIs(fieldName, "building")) {
        if (!ValidateNotEmpty(form.building)) {
            errors.push({ fieldName: "building", id: "NotEmpty", error: "Pole nie może być puste" });
        }
    }

    if (fieldNameIs(fieldName, "zipCode")) {
        if (!ValidateZipCode(form.zipCode)) {
            errors.push({ fieldName: "zipCode", id: "InvalidZipCode", error: "Niepoprawny kod pocztowy" });
        }
    }

    if (fieldNameIs(fieldName, "city")) {
        if (!ValidateNotEmpty(form.city)) {
            errors.push({ fieldName: "city", id: "NotEmpty", error: "Pole nie może być puste" });
        }
    }

    return {
        errors: errors
    };
};
