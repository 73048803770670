import React, { useEffect, useState } from "react";
import { Endpoint } from "@schneider-ecommerce/client-endpoint";

import { useDatasource } from "../../hooks/useDatasource";
import { formatPrice } from "../../utils/FormatPrice";
import { Product, ShipmentMethod, DiscountCode } from "./Checkout.types";

import "./ProductAndShipment.scss";
import { discountToValue } from "../../utils/DiscountToValue";

interface ProductAndShipmentProps {
    products: Product[];
    shipmentMethods: ShipmentMethod[];
    discountCodes: DiscountCode[];
    total: number;
    totalDiscounted: number;
    isGoNextDisabled: boolean;
    changeProductQuantityStep: (productId: number, direction: -1 | 1) => void;
    setShipment: (shipmentMethodsId: number) => void;
    addDiscountCode: (discountCode: DiscountCode) => void;
    removeDiscountCode: (discountCodeId: number) => void;
    goNext: () => void;
}

const productPhotos = {
    1: "/assets/poradnik.jpg"
};

const ProductAndShipment: React.FC<ProductAndShipmentProps> = ({
    products,
    shipmentMethods,
    discountCodes,
    total,
    totalDiscounted,
    isGoNextDisabled,
    changeProductQuantityStep,
    addDiscountCode,
    removeDiscountCode,
    setShipment,
    goNext
}) => {
    const [discountCode, setDiscountCode] = useState<string>("");
    const [discountCodeError, setDiscountCodeError] = useState<string | undefined>(undefined);
    const [showDiscountCodeForm, setShowDiscountCodeForm] = useState<boolean>(false);

    const discountCodeInputRef = React.createRef<HTMLInputElement>();

    const dsCheckCode = useDatasource(Endpoint.DiscountCode.PostCheckDiscountCode);

    const isDiscountFormDisabled = dsCheckCode.state.state === "pending";

    const isDiscountedTotal = total !== totalDiscounted;

    useEffect(() => {
        if (dsCheckCode.state.state === "completed") {
            const response = dsCheckCode.state.response;
            addDiscountCode({ ...response });
            setDiscountCode("");
            setShowDiscountCodeForm(false);
        } else if (dsCheckCode.state.state === "error") {
            setDiscountCodeError(dsCheckCode.state.message);
        }
    }, [dsCheckCode.state.state]);

    useEffect(() => {
        validateDiscountCode(true);
    }, [discountCode]);

    useEffect(() => {
        if (showDiscountCodeForm && discountCodeInputRef.current) {
            discountCodeInputRef.current.focus();
        }
    }, [showDiscountCodeForm]);

    return (
        <div className="product-and-shipment">
            <div className="product-and-shipment__title">Wybierz produkty i metodę dostawy</div>
            <div className="product-and-shipment__products">
                {products.map(p => {
                    const photoUrl = productPhotos[p.productId as keyof typeof productPhotos];
                    const isDiscounted = p.priceDiscounted !== p.price;
                    return (
                        <div className="product-and-shipment__product" key={p.productId}>
                            <div className="product-and-shipment__product-photo">
                                {!!photoUrl ? (
                                    <img
                                        src={photoUrl}
                                        alt={`Zdjęcie produktu - ${p.label}`}
                                        className="product-and-shipment__product-photo-img"
                                    />
                                ) : (
                                    <div className="product-and-shipment__product-photo-placeholder">image</div>
                                )}
                            </div>
                            <div className="product-and-shipment__product-info">
                                <div className="product-and-shipment__product-label">{p.label}</div>
                                <div className="product-and-shipment__product-controls">
                                    <div className="product-and-shipment__product-value">
                                        <div className="product-and-shipment__product-value-label">Cena / szt</div>
                                        <div className="product-and-shipment__product-value-price">
                                            <div
                                                className={`product-and-shipment__product-value-normal ${
                                                    isDiscounted ? "is-discounted" : ""
                                                }`}
                                            >
                                                {formatPrice(p.price)} PLN
                                            </div>
                                            {isDiscounted ? (
                                                <div className="product-and-shipment__product-value-discounted">
                                                    {formatPrice(p.priceDiscounted)} PLN
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="product-and-shipment__product-value">
                                        <div className="product-and-shipment__product-value-label">Ilość</div>
                                        <div className="product-and-shipment__product-counter">
                                            <div
                                                className={`product-and-shipment__product-counter-button ${
                                                    p.quantity <= 0 ? "is-disabled" : ""
                                                }`}
                                                onClick={() => changeProductQuantityStep(p.productId, -1)}
                                            >
                                                remove
                                            </div>
                                            <div className="product-and-shipment__product-counter-value">{p.quantity}</div>
                                            <div
                                                className={`product-and-shipment__product-counter-button ${
                                                    p.quantity >= p.stock ? "is-disabled" : ""
                                                }`}
                                                onClick={() => changeProductQuantityStep(p.productId, 1)}
                                            >
                                                add
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-and-shipment__product-value">
                                        <div className="product-and-shipment__product-value-label">Koszt</div>
                                        <div className="product-and-shipment__product-value-price">
                                            <div
                                                className={`product-and-shipment__product-value-normal ${
                                                    isDiscounted ? "is-discounted" : ""
                                                }`}
                                            >
                                                {formatPrice(p.total)} PLN
                                            </div>
                                            {isDiscounted ? (
                                                <div className="product-and-shipment__product-value-discounted">
                                                    {formatPrice(p.totalDiscounted)} PLN
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className="product-and-shipment__shipments">
                {shipmentMethods.map(sm => {
                    const isDiscounted = sm.price !== sm.priceDiscounted;
                    return (
                        <div
                            className={`product-and-shipment__shipment ${sm.isSelected ? "is-selected" : ""} ${
                                sm.isActive ? "" : "is-disabled"
                            }`}
                            key={sm.shipmentMethodId}
                            onClick={() => setShipment(sm.shipmentMethodId)}
                        >
                            <div className="product-and-shipment__shipment-option"></div>
                            <div className="product-and-shipment__shipment-label">{sm.label}</div>
                            <div className="product-and-shipment__shipment-price">
                                <div className={`product-and-shipment__shipment-price-value ${isDiscounted ? "is-discounted" : ""}`}>
                                    {formatPrice(sm.price)} PLN
                                </div>
                                {isDiscounted ? (
                                    <div className="product-and-shipment__shipment-price-discounted">
                                        {formatPrice(sm.priceDiscounted)} PLN
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className="product-and-shipment__discounts">
                {discountCodes.map(dc => {
                    return (
                        <div className="product-and-shipment__discount" key={dc.discountCodeId}>
                            <div
                                className="product-and-shipment__discount-form-close"
                                onClick={() => removeDiscountCode(dc.discountCodeId)}
                            >
                                close
                            </div>
                            <div className="product-and-shipment__discount-label">{dc.label}</div>
                            <div className="product-and-shipment__discount-value">{discountToValue(dc)}</div>
                        </div>
                    );
                })}
                {showDiscountCodeForm ? (
                    <div className="product-and-shipment__discount-form">
                        <div className="product-and-shipment__discount-form-close" onClick={() => setShowDiscountCodeForm(false)}>
                            close
                        </div>
                        <input
                            type="text"
                            className="product-and-shipment__discount-form-input"
                            value={discountCode}
                            disabled={isDiscountFormDisabled}
                            onChange={e => setDiscountCode(e.target.value)}
                            placeholder="KOD RABATOWY"
                            onKeyDown={e => checkIfEnter(e)}
                            ref={discountCodeInputRef}
                        />
                        <button
                            className="product-and-shipment__discount-form-button"
                            disabled={isDiscountFormDisabled}
                            onClick={() => checkCode()}
                        >
                            Dodaj
                        </button>
                        {discountCodeError ? <div className="product-and-shipment__discount-form-error">{discountCodeError}</div> : null}
                    </div>
                ) : (
                    <div className="product-and-shipment__discount-add-button" onClick={() => setShowDiscountCodeForm(true)}>
                        <i>add</i> Dodaj kod rabatowy
                    </div>
                )}
            </div>

            <div className="product-and-shipment__summary">
                <div className={`product-and-shipment__summary-row ${isDiscountedTotal ? "is-discounted" : ""}`}>
                    <div className="product-and-shipment__summary-label">Do zapłaty</div>
                    <div className="product-and-shipment__summary-value">{formatPrice(total)} PLN</div>
                </div>

                {isDiscountedTotal ? (
                    <div className={`product-and-shipment__summary-row `}>
                        <div className="product-and-shipment__summary-label">Do zapłaty po rabatach</div>
                        <div className="product-and-shipment__summary-value">{formatPrice(totalDiscounted)} PLN</div>
                    </div>
                ) : null}
            </div>

            <div className="product-and-shipment__footer">
                <button className="button" disabled={isGoNextDisabled} onClick={() => goNext()}>
                    Dalej
                </button>
            </div>
        </div>
    );

    function checkCode() {
        if (!validateDiscountCode() || dsCheckCode.state.state === "pending") return;

        dsCheckCode.load({
            data: {
                discountCode: discountCode,
                activeDiscountCodeIds: discountCodes.map(dc => dc.discountCodeId)
            }
        });
    }

    function validateDiscountCode(reduce?: boolean) {
        if (discountCode.length < 3) {
            if (!reduce) {
                setDiscountCodeError("Kod musi mieć minimum 3 znaki");
            }
            return false;
        }
        setDiscountCodeError(undefined);
        return true;
    }

    function checkIfEnter(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            checkCode();
        }
    }
};

export default ProductAndShipment;
