import { Base, ObjectValidateDefinition, validateObject } from "@nexcodepl/type-checking";
import { useEffect, useState } from "react";

export function useStateWithLocalStorage<T extends Base<T>>(
    key: string,
    initialObject: T,
    validateDefinition: ObjectValidateDefinition<T>
) {
    const [data, setData] = useState<T>(getDataFromLocalStorage() || initialObject);

    useEffect(() => {
        setDataToLocalStorage(key, data);
    }, [data, key]);

    return { data, setData, setDataToLocalStorage };

    function setDataToLocalStorage(key: string, data: T) {
        try {
            window.localStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
            console.warn("useStateWithLocalStorage hook error: data could not be stringify");
        }
    }

    function getDataFromLocalStorage() {
        const dataFromLocalStorage = window.localStorage.getItem(key);

        if (!dataFromLocalStorage || typeof dataFromLocalStorage !== "string") return null;

        try {
            const parsedObject: T = JSON.parse(dataFromLocalStorage);

            if (validateObject(parsedObject, validateDefinition)) {
                return parsedObject;
            }

            return null;
        } catch (e) {
            return null;
        }
    }
}
