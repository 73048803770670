import React from "react";

import "./FieldWrapper.scss";

export interface FieldWrapperProps {
    title?: string;
    description?: string;
    errors?: string[];
}

const FieldWrapper: React.FC<FieldWrapperProps> = ({ children, title, description, errors }) => {
    return (
        <div className="field-wrapper">
            {title ? <div className="field-wrapper__title">{title}</div> : null}
            {description ? <div className="field-wrapper__description">{description}</div> : null}
            <div className="field-wrapper__children">{children}</div>
            {errors ? (
                <div className="field-wrapper__errors">
                    {errors.map((error, index) => (
                        <div className="field-wrapper__error" key={index}>
                            {error}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default FieldWrapper;
