import { useLocation } from "react-router-dom";

export function useSearchParam(paramName: string, type: "string"): string | undefined;
export function useSearchParam(paramName: string, type: "number"): number | undefined;
export function useSearchParam(paramName: string, type: "boolean"): boolean | undefined;
export function useSearchParam(paramName: string, type: "string" | "number" | "boolean"): string | number | boolean | undefined {
    const location = useLocation();

    const urlParams = new URLSearchParams(location.search);
    const param = urlParams.get(paramName);
    
    // const paramsValues = location.search.replace("?", "").split("&");

    // if (paramsValues.length < 1) return undefined;

    // const urlParams = paramsValues.reduce((up, pv) => {
    //     const paramValue = pv.split("=");
    //     if (paramValue.length !== 2) return up;
    //     up[paramValue[0]] = paramValue[1];
    //     return up;
    // }, {} as { [key: string]: string });

    // const param = urlParams[paramName];

    if (!param) {
        return undefined;
    }

    if (type === "string") {
        return param;
    }

    if (type === "number") {
        const paramInt = parseInt(param);
        if (Number.isInteger(paramInt)) {
            return paramInt;
        }
    }

    if (type === "boolean") {
        if (param === "true") {
            return true;
        } else if (param === "false") {
            return false;
        }
    }

    return undefined;
}
