import React from "react";

const Regulamin: React.FC = () => {
    return (
        <div className="regulamin">
            <div className="regulamin__center">
                <div className="regulamin__header">regulamin sklepu internetowego Poradnik Inżyniera Elektryka </div>
                <div className="regulamin__text">
                    <br />
                    <br />
                    <b>Definicje</b> <br />
                    <br />
                    Użyte w poniższym regulaminie pojęcia oznaczają: <br />
                    <br />
                    1.<b> Sklep Internetowy (Sklep) –</b> serwis internetowy dostępny pod www.poradnik-inzyniera-elektryka.pl
                    <br />
                    <br />
                    2.<b> Klient –</b> osoba fizyczna, osoba prawna lub jednostka organizacyjna nie będącą osobą prawną, której przepisy
                    szczególne przyznają zdolność prawną, która dokonuje Zamówienia w ramach Sklepu;
                    <br />
                    <br />
                    3.<b> Konsument -</b> osoba fizyczna dokonująca czynności prawnej niezwiązanej bezpośrednio z jej działalnością
                    gospodarczą lub zawodową.
                    <br />
                    <br />
                    4.<b> Zamówienie –</b> oświadczenie woli Klienta, zmierzające bezpośrednio do zawarcia Umowy sprzedaży, określające w
                    szczególności rodzaj i liczbę Towaru.
                    <br />
                    <br />
                    5.<b> Regulamin –</b> niniejszy Regulamin świadczenia usług drogą elektroniczną w ramach sklepu internetowego
                    www.poradnik-inzyniera-elektryka.pl
                    <br />
                    <br />
                    6.<b> Towar –</b> produkty prezentowane w Sklepie Internetowym;
                    <br />
                    <br />
                    7.<b> Umowa sprzedaży –</b> umowa sprzedaży Towaru w rozumieniu Kodeksu Cywilnego, zawarta pomiędzy
                    <b>NEATI Maciej Byczek</b> a Klientem;
                    <br />
                    <br />
                    8.<b> Ustawa o szczególnych warunkach sprzedaży konsumenckiej –</b> ustawa z dnia 27 lipca 2002 r. o szczególnych
                    warunkach sprzedaży konsumenckiej oraz o zmianie Kodeksu Cywilnego (Dz. U. Nr 141, poz. 1176 ze zm.); <br />
                    <br />
                    9. <b>Ustawa o prawach konsumenta -</b> ustawa z dnia 30 maja 2014 r. ( Dz. U. 2014 poz. 827 ze zm.) <br />
                    <br />
                    10.<b> Kodeks Cywilny –</b> ustawa z dnia 23 kwietnia 1964 r. (Dz. U. Nr 16, poz. 93 ze zm.). <br />
                    <br />
                    <b>I. Postanowienia ogólne</b> <br />
                    <br />
                    1.1. Sklep Internetowy pod nazwą www.poradnik-inzyniera-elektryka.pl jest prowadzony przez <b>
                        NEATI Maciej Byczek
                    </b> w <b>Jadachy 352, 39-442 Jadachy</b>. Posiadającą NIP: <b>8672252019</b> <br />
                    <br />
                    1.2. Zamówienia mogą składać osoby fizyczne posiadające pełną zdolność do czynności prawnych oraz podmioty prowadzące
                    działalność gospodarczą. <br />
                    <br />
                    1.3. Klient może uzyskać dostęp do Regulaminu w każdym czasie za pośrednictwem odsyłacza zamieszczonego na stronie
                    głównej serwisu www.poradnik-&nbsp;inzyniera-&nbsp;elektryka.pl oraz pobrać go i sporządzić jego wydruk. <br />
                    <br />
                    1.4. Informacje o Towarze podane na stronach Sklepu Internetowego, w szczególności opisy, parametry techniczne i
                    użytkowe oraz ceny, stanowią zaproszenie do zawarcia Umowy Sprzedaży, w rozumieniu art. 71 Kodeksu Cywilnego. <br />
                    <br />
                    <b>II. Zasady korzystania ze Sklepu Internetowego</b> <br />
                    <br />
                    2.1. Warunkiem złożenia Zamówienia i dokonania zakupu w Sklepie Internetowym jest akceptacja Regulaminu oraz podanie
                    danych adresowych i kontaktowych, niezbędnych do dokonania wysyłki zamówionego Towaru. <br />
                    <br />
                    2.2 W przypadku, gdy Klient wyraża wolę otrzymania faktury VAT, zobowiązany jest też do podania nazwy podmiotu i numeru
                    NIP. <br />
                    <br />
                    2.3. Klient może dokonać rejestracji w systemie Sklepu Internetowego, co skróci procedurę składania kolejnych Zamówień.
                    Rejestracja następuje poprzez wypełnienie formularza w wersji elektronicznej. <br />
                    <br />
                    2.4. Warunkiem rejestracji jest zaakceptowanie treści Regulaminu oraz podanie danych adresowych dla dostarczania
                    produktów danych i kontaktowych. <br />
                    <br />
                    2.5. Składając Zamówienie i/lub dokonując rejestracji w Sklepie Internetowym Klient wyraża zgodę na przetwarzanie swoich
                    danych osobowych w celu realizacji Zamówienia. <br />
                    <br />
                    2.6. Dane Klienta nie zostaną nikomu przekazane dla celów marketingowych i będą wykorzystywane wyłącznie do realizacji
                    Zamówienia i prowadzenia statystyk sprzedaży, a także ze względów rachunkowych lub podatkowych. <br />
                    <br />
                    2.7. Dane są przechowywane nie dłużej niż jest to konieczne do realizacji celów, dla których je zgromadzono, chyba że
                    obowiązujące przepisy prawa wymagają od nas ich dłuższego przechowywania. <br />
                    <br />
                    2.8. Administratorem danych jest <b>NEATI Maciej Byczek</b> <br />
                    <br />
                    2.9. Klient ma prawo: do dostępu do swoich danych, do ich sprostowania, odmowy podlegania zautomatyzowanemu
                    przetwarzaniu, do ograniczenia lub usunięcia, a także do ich przeniesienia. 2.10. Klient ma prawo do uzyskania
                    informacji na temat przetwarzania swoich danych osobowych poprzez kontakt e-mail: <b>biuro@neati.pl</b> <br />
                    <br />
                    2.10. Klient ma prawo wniesienia skargi dotyczącej sposobu przetwarzania danych osobowych, do organu nadzorczego. <br />
                    <br />
                    <b>III. Zasady składania Zamówienia</b> <br />
                    <br />
                    W celu złożenia Zamówienia należy: <br />
                    <br />
                    3.1. Wybrać produkt będący Przedmiotem zamówienia na stronie www.poradnik-inzyniera-elektryka.pl <br />
                    <br />
                    3.2. Po przekierowaniu do Koszyka należy podać dane osobowe oraz dane do wysyłki; 3.3. Kliknąć przycisk "Zamawiam i
                    płacę" oraz postępować zgodnie z instrukcjami. <br />
                    <br />
                    <b>IV. Procedura zawarcia Umowy sprzedaży</b> <br />
                    <br />
                    4.1. Po podaniu przez Klienta wszystkich wymaganych danych, wyświetlone zostanie podsumowanie złożonego Zamówienia,
                    które będzie zawierać informacje dotyczące: <br />
                    <br />
                    1. a) przedmiotu Zamówienia, <br />
                    <br />
                    2. b) jednostkowej oraz łącznej ceny zamówionych produktów oraz kosztu dostawy, <br />
                    <br />
                    3. c) wybranej metody płatności, <br />
                    <br />
                    4. d) wybranego sposobu i terminu dostawy, <br />
                    <br />
                    4.2. Złożenie przez Klienta Zamówienia następuje poprzez naciśnięcie przycisku „zamawiam” i stanowi oświadczenie woli
                    zawarcia z <b>NEATI Maciej Byczek</b> umowy sprzedaży. <br />
                    <br />
                    4.3. Po złożeniu Zamówienia, Klient otrzyma wiadomość e-mail zatytułowaną: zamówienie nr [...] i zawierającą
                    potwierdzenie wszystkich elementów Zamówienia oraz dane potrzebne przy dokonywaniu płatności przelewem bankowym, jeżeli
                    taką formę zapłaty Klient wybrał. <br />
                    <br />
                    4.4. Umowę traktuje się za zawartą z momentem otrzymania przez Klienta wiadomości e-mail, o której mowa powyżej. <br />
                    <br />
                    <b>V. Dostawa</b> <br />
                    <br />
                    5.1. Dostawa Towaru jest ograniczona do obszaru Rzeczpospolitej Polskiej i odbywa się pod adres wskazany przez Klienta w
                    trakcie składania Zamówienia. <br />
                    <br />
                    5.2. Wraz z dostarczonym Towarem Klient otrzymuje dokument potwierdzający dokonanie transakcji: paragon lub fakturę VAT.{" "}
                    <br />
                    <br />
                    5.3. Dostawa zamówionego Towaru odbywa się przesyłką kurierską lub za pośrednictwem Poczty Polskiej, albo kuriera DPD.
                    Koszty dostawy zależą od ciężaru i wielkości zamówionego Towaru oraz wybranej metody doręczenia. Są one każdorazowo
                    wskazane w czasie składania Zamówienia. <br />
                    <br />
                    5.3.1. Maksymalne gabaryty przesyłki, przeznaczonej do odbioru u kuriera DPD, są ograniczone. W przypadku braku
                    możliwości zrealizowania dostawy poprzez kuriera DPD, Klient zostanie poinformowany o innych warunkach dostawy. <br />
                    <br />
                    5.3.2. Koszty dostawy większych ilości towaru lub produktów o niestandardowych wymiarach mogą podlegać dodatkowym
                    opłatom. W takim przypadku Klient zostanie poinformowany o warunkach dostawy przed wysyłką Towaru. <br />
                    <br />
                    5.4. W przypadku Towaru określonego jako dostępny, termin dostawy wynosi od 1 do 6 dni roboczych, licząc od momentu
                    otrzymania wpłaty lub wpłynięcia zamówienia z wybraną formą płatności: za pobraniem. <br />
                    <br />
                    5.5. W przypadku Towaru określonego jako dostępny na zamówienie Klient, przed złożeniem Zamówienia, może uzyskać
                    telefonicznie lub pocztą elektroniczną informację o przewidywanej dacie dostawy. <br />
                    <br />
                    5.6. W innych przypadkach, np. w razie czasowego braku Towaru Klient, przed złożeniem Zamówienia, może uzyskać
                    telefonicznie lub pocztą elektroniczną informację o przewidywanej dacie dostawy. <br />
                    <br />
                    <b>VI. Ceny i metody płatności</b> <br />
                    <br />
                    6.1. Cena Towaru znajdującego się w Sklepie Internetowym podana jest w złotych polskich, zawiera wszystkie składniki, w
                    tym podatek VAT oraz obowiązuje w chwili składania Zamówienia. Cena Towaru nie uwzględnia kosztów jego dostawy. <br />
                    <br />
                    6.2. Klient ma możliwość dokonania zapłaty: <br />
                    <br />
                    5. a) płatnością w systemie elektronicznym. <br />
                    <br />
                    c) gotówką lub kartą przy dostawie przesyłki (pobranie) <br />
                    <br />
                    <b>VII. Uprawnienia do odstąpienia od umowy przez Klienta</b> <br />
                    <br />
                    7.1. Klient, będący Konsumentem, może zwrócić Towar kupiony w Sklepie Internetowym. Odstąpienie od umowy następuje
                    poprzez złożenie oświadczenia drogą mailową lub pisemną w ciągu 14 dni od daty doręczenia przesyłki. <br />
                    <br />
                    7.2. Po złożeniu oświadczenia umowa jest uważana za niezawartą, a Klient ponosi odpowiedzialność za zmniejszenie
                    wartości Towaru będące wynikiem korzystania z niego w sposób wykraczający poza konieczny do stwierdzenia charakteru,
                    cech i funkcjonowania Towaru. <br />
                    <br />
                    7.3. Zwrot Towaru lub Wymiana Towaru powinny nastąpić niezwłocznie, nie później niż w terminie 14 dni od daty złożenia
                    oświadczenia o odstąpieniu od umowy lub o wymianie produktu. <br />
                    <br />
                    7.4. Zwracany Towar należy opakować w sposób uniemożliwiający uszkodzenie podczas transportu, a następnie wysłać na
                    adres: <b>NEATI, Bogucka 15, 32-020 Wieliczka</b> <br />
                    <br />
                    7.5. Kwota wynikająca z rezygnacji z zakupu zostanie zwrócona Klientowi niezwłocznie, nie później jednak niż w terminie
                    czternastu dni po otrzymaniu oświadczenia. Sklep może się wstrzymać z odesłaniem środków do czasu otrzymania zwracanego
                    Towaru. <br />
                    <br />
                    7.6. Sklep zwraca środki przy użyciu takiego sposobu, jaki zostanie ustalony z Klientem. <br />
                    <br />
                    7.7. Przesyłki ze zwrotami wysyłane do Sklepu Internetowego w trybie „za pobraniem” nie będą przyjmowane. <br />
                    <br />
                    7.8. Odstąpienie od umowy nie przysługuje w przypadku: <br />
                    <br />
                    a/ umowy, w której cena lub wynagrodzenie zależy od wahań na rynku finansowym nad którymi przedsiębiorca nie sprawuje
                    kontroli, i które mogą wystąpić przed upływem terminu do odstąpienia od umowy <br />
                    <br />
                    b/ umowy, w której przedmiotem świadczeń jest rzecz nieprefabrykowana, wyprodukowana lub przygotowana według
                    specyfikacji konsumenta lub służąca zaspokojeniu jego zindywidualizowanych potrzeb. <br />
                    <br />
                    c/ umowy, w której przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na swój charakter, zostają
                    nierozłącznie połączone z innymi rzeczami <br />
                    <br />
                    d/ umowy, w której przedmiotem świadczenia są nagrania lub wizualne albo programy komputerowe dostarczane w
                    zapieczętowanym opakowaniu, jeżeli opakowanie zostało otwarte po dostarczeniu Poradnika Inżyniera Elektryka
                    <br />
                    <br />
                    e/ umowy, w której przedmiotem świadczenia są przedmioty dostarczane w zapieczętowanym opakowaniu typu blister lub
                    podobnym, jeżeli opakowanie zostało otwarte po dostarczeniu do Klienta. <br />
                    <br />
                    <b>VIII. Postanowienia końcowe</b> <br />
                    <br />
                    8.1. Rozstrzyganie ewentualnych sporów powstałych pomiędzy <b>NEATI Maciej Byczek</b> a Klientem, który jest
                    Konsumentem, zostaje poddane sądom właściwym zgodnie z postanowieniami Kodeksu Cywilnego. <br />
                    <br />
                    8.2. Rozstrzyganie ewentualnych sporów powstałych pomiędzy <b>NEATI Maciej Byczek</b> a Klientem, który nie jest
                    Konsumentem zostaje poddane sądowi właściwemu ze względu na siedzibę <b>NEATI Maciej Byczek.</b> <br />
                    <br />
                    8.3. W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie przepisy Kodeksu cywilnego, Ustawy o
                    szczególnych warunkach sprzedaży konsumenckiej, a także Ustawy o ochronie niektórych praw konsumentów. <br />
                    <br />
                </div>
            </div>
        </div>
    );
};

export default Regulamin;
