import React from "react";
import { discountToValue } from "../../utils/DiscountToValue";
import { formatPrice } from "../../utils/FormatPrice";
import { DiscountCode, Product, ShipmentMethod } from "./Checkout.types";

import "./SummaryProductAndShipment.scss";

interface SummaryProps {
    products: Product[];
    shipmentMethod: ShipmentMethod | undefined;
    discountCodes: DiscountCode[];
    total: number;
    totalDiscounted: number;
    goBack?: () => void;
}

const SummaryProductsAndShipment: React.FC<SummaryProps> = ({
    products,
    shipmentMethod,
    discountCodes,
    total,
    totalDiscounted,
    goBack
}) => {
    return (
        <>
            <div className="summary-products-and-shipment__title">Wybrane produkty i metoda dostawy</div>
            <div className="summary-products-and-shipment">
                <div className="summary-products-and-shipment__products">
                    {products.map(p => (
                        <div className="summary-products-and-shipment__product" key={p.productId}>
                            <div className="summary-products-and-shipment__product-label">{p.label}</div>
                            <div className="summary-products-and-shipment__product-order">
                                {p.quantity} x {formatPrice(p.priceDiscounted)} PLN = <b>{formatPrice(p.totalDiscounted)} PLN</b>
                            </div>
                        </div>
                    ))}
                </div>
                {shipmentMethod ? (
                    <div className="summary-products-and-shipment__shipment">
                        <div className="summary-products-and-shipment__shipment-label">{shipmentMethod.label}</div>
                        <div className="summary-products-and-shipment__shipment-value">
                            {formatPrice(shipmentMethod.priceDiscounted)} PLN
                        </div>
                    </div>
                ) : null}
                {discountCodes.length > 0 ? <div className="summary-products-and-shipment__discount-codes">
                    {discountCodes.map(dc => (
                        <div className="summary-products-and-shipment__discount-code" key={dc.discountCodeId}>
                            <div className="summary-products-and-shipment__discount-code-label">{dc.label}</div>
                            <div className="summary-products-and-shipment__discount-code-value">{discountToValue(dc)}</div>
                        </div>
                    ))}
                </div> : null}
                <div className="summary-products-and-shipment__total">
                    <div className="summary-products-and-shipment__total-label">SUMA</div>
                    <div className="summary-products-and-shipment__total-value">{formatPrice(totalDiscounted)} PLN</div>
                </div>

                {goBack ? (
                    <div className="summary-products-and-shipment__button" onClick={() => goBack()}>
                        Edytuj zamówienie
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default SummaryProductsAndShipment;
