import { DiscountCode } from "../areas/Checkout/Checkout.types";
import { formatPrice } from "./FormatPrice";

export function discountToValue(discount: DiscountCode): string {
    switch (discount.type) {
        case "amountShipment":
            return `- ${formatPrice(discount.amount)} PLN`;

        case "percentProduct":
            return `- ${discount.amount}%`;
    }
}
