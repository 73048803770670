import React from "react";
import { CustomerType } from "@schneider-ecommerce/utils-generic";

import { useForm } from "../../form/Form";
import TextField from "../../form/TextField";
import { Address, Customer } from "./Checkout.types";
import { validateFormAddress, validateFormCustomer } from "./CheckoutForm.validation";
import TextSwitch from "../../components/TextSwitch";

import "./CheckoutForm.scss";

interface CheckoutFormProps {
    customerType: CustomerType;
    customer: Customer;
    address: Address;
    setCustomerType: (customerType: CustomerType) => void;
    setCustomer: (f: (customer: Customer) => Customer) => void;
    setAddress: (f: (address: Address) => Address) => void;
    goNext: () => void;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
    customer,
    address,
    setAddress,
    setCustomer,
    customerType,
    setCustomerType,
    goNext,
    ...props
}) => {
    const formCustomer = useForm(customer, setCustomer, validateFormCustomer, undefined, false, { customerType: customerType });
    const formAddress = useForm(address, setAddress, validateFormAddress);

    return (
        <div className="checkout-form">
            <div className="checkout-form__title">Dane zamawiającego</div>

            <div className="checkout-form__section">
                <div className="checkout-form__customer-type-chooser">
                    <TextSwitch
                        options={[
                            { id: 1, label: "Osoba prywatna" },
                            { id: 2, label: "Firma" }
                        ]}
                        value={customerType}
                        setValue={value => setCustomerType(value as 1 | 2)}
                    />
                </div>
                <TextField title="Email" {...formCustomer.fieldProps("email")} />
                <TextField title="Telefon" {...formCustomer.fieldProps("phone")} />
                <TextField title={customerType === 1 ? "Imię i Nazwisko" : "Nazwa firmy"} {...formCustomer.fieldProps("name")} />
                {customerType === 2 ? <TextField title="NIP" {...formCustomer.fieldProps("taxId")} /> : null}
            </div>

            <div className="checkout-form__title">Dane do wysyłki</div>

            <div className="checkout-form__section">
                <TextField title="Odbiorca" {...formAddress.fieldProps("receipient")} />
                <div className="checkout-form__section-row">
                    <TextField title="Ulica" {...formAddress.fieldProps("street")} />
                    <TextField title="Budynek / Lokal" {...formAddress.fieldProps("building")} />
                </div>
                <div className="checkout-form__section-row">
                    <TextField title="Kod pocztowy" {...formAddress.fieldProps("zipCode")} />
                    <TextField title="Miasto" {...formAddress.fieldProps("city")} />
                </div>
                <TextField title="Komentarz dla kuriera" {...formAddress.fieldProps("comment")} />
            </div>

            <div className="checkout-form__button">
                <button className="button" onClick={() => validate()}>
                    Dalej
                </button>
            </div>
        </div>
    );

    async function validate() {
        const formCustomerResult = await formCustomer.validateForm();
        const formAddressResult = await formAddress.validateForm();
        if (formAddressResult && formCustomerResult) {
            goNext();
        }
    }
};

export default CheckoutForm;
