import React from "react";
import "./Checkbox.scss";
interface Props {
    value: boolean;
    changeValue: (value: boolean) => void;
    label?: string;
}
const Checkbox: React.FC<Props> = ({ value, changeValue, label, children }) => {
    return (
        <div className="form__checkbox">
            <div className={`form__checkbox-square ${value ? "is-checked" : ""}`}>check</div>
            <input type="checkbox" checked={value} onChange={e => changeValue(e.target.checked)} className="form__checkbox-input" />

            <div className="form__checkbox-label" onClick={() => changeValue(!value)}>
                {children ? children : label || ""}
            </div>
        </div>
    );
};

export default Checkbox;
