import React, { useEffect, useState } from "react";
import { useDatasource } from "../../hooks/useDatasource";

import { Endpoint } from "@schneider-ecommerce/client-endpoint";
import {
    CalculateDiscountedPriceProduct,
    CalculateDiscountedPriceShipment,
    CalculateDiscountedPrice
} from "@schneider-ecommerce/utils-generic";

import { useStateWithLocalStorage } from "../../hooks/useLocalstorage";
import { CheckoutState, DiscountCode, Product, ShipmentMethod, CheckoutStateProduct } from "./Checkout.types";
import { checkoutStateValidateDefinition } from "./Checkout.validation";

import ProductAndShipment from "./ProductAndShipment";
import SummaryProductAndShipment from "./SummaryProductAndShipment";
import CheckoutForm from "./CheckoutForm";
import SummaryCustomerAndAddress from "./SummaryCustomerAndAddress";
import Terms from "./Terms";

import "./Checkout.scss";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../App";

const defaultCheckoutState: CheckoutState = {
    productList: [],
    discountCodes: [],
    shipmentMethodId: undefined,
    customer: {
        name: "",
        email: "",
        phone: "",
        taxId: ""
    },
    address: {
        receipient: "",
        street: "",
        building: "",
        zipCode: "",
        city: "",
        comment: ""
    },
    customerType: 1,
    step: 1
};

const Checkout: React.FC = () => {
    const history = useHistory();
    const { data, setData, setDataToLocalStorage } = useStateWithLocalStorage<CheckoutState>(
        "checkout",
        defaultCheckoutState,
        checkoutStateValidateDefinition
    );
    const dsListing = useDatasource(Endpoint.Listing.GetListing);
    const dsOrderCreate = useDatasource(Endpoint.Order.PostCreate);

    const dsListingProducts = dsListing.state.state === "completed" ? dsListing.state.response.products : [];
    const dsListingShipmentMethods = dsListing.state.state === "completed" ? dsListing.state.response.shipmentMethods : [];

    useEffect(() => {
        loadListing();
    }, []);

    useEffect(() => {
        if (dsListing.state.state === "completed") {
            setDataPartial(p => {
                const productList = p.productList.reduce((_pl, _p) => {
                    const _product = dsListingProducts.find(e => e.productId === _p.productId);

                    if (!_product) return _pl;

                    _pl.push({ productId: _p.productId, quantity: _product.stock < _p.quantity ? _product.stock : _p.quantity });

                    return _pl;
                }, [] as CheckoutStateProduct[]);

                return { productList: productList };
            });

            if (dsListing.state.response.shipmentMethods.length > 0) {
                const shipmentMethod = dsListing.state.response.shipmentMethods.find(e => e.shipmentMethodId === data.shipmentMethodId);
                if (!shipmentMethod) {
                    setShipmentMethod(dsListing.state.response.shipmentMethods[0].shipmentMethodId);
                }
            }
        }
    }, [dsListing.state]);

    useEffect(() => {
        if (dsOrderCreate.state.state === "completed") {
            setDataToLocalStorage("checkout", defaultCheckoutState);
            history.push({ pathname: ROUTES.Order, search: `orderGuid=${dsOrderCreate.state.response.orderGuid}` });
        }
    }, [dsOrderCreate.state]);

    if (dsListing.state.state === "idle" || dsListing.state.state === "pending") {
        return (
            <div className="checkout">
                <div className="checkout__title">Ładowanie...</div>
                <div className="checkout__subtitle">Trwa ładowanie produktów i metod płatności</div>
            </div>
        );
    }

    if (dsListing.state.state === "error") {
        return (
            <div className="checkout">
                <div className="checkout__title">Błąd ładowania :(</div>
                <div className="checkout__subtitle">
                    Wystąpił błąd podczas ładowania produktów.
                    <br />
                    <br />
                    {dsListing.state.message || "Wystąpił nieznany błąd"}
                </div>
            </div>
        );
    }

    const discountCodes: DiscountCode[] = data.discountCodes;

    const productList: Product[] = dsListing.state.response.products.map(p => {
        const savedProduct = data.productList.find(e => e.productId === p.productId);
        const quantity = savedProduct?.quantity ?? 0;
        const priceDiscounted = CalculateDiscountedPriceProduct(p.priceGross, discountCodes);

        const product: Product = {
            productId: p.productId,
            label: p.label,
            price: p.priceGross,
            priceDiscounted: priceDiscounted,
            quantity: quantity,
            total: quantity * p.priceGross,
            totalDiscounted: quantity * priceDiscounted,
            stock: p.stock
        };

        return product;
    });

    const productTotalCount = productList.reduce((total, p) => total + p.quantity, 0);

    const shipmentMethodList: ShipmentMethod[] = dsListingShipmentMethods.map(sm => {
        const priceDiscounted = CalculateDiscountedPriceShipment(sm.price, discountCodes);
        const shipmentMethod: ShipmentMethod = {
            shipmentMethodId: sm.shipmentMethodId,
            label: sm.label,
            price: sm.price,
            priceDiscounted: priceDiscounted,
            isActive: sm.isActive,
            isSelected: data.shipmentMethodId === sm.shipmentMethodId
        };
        return shipmentMethod;
    });

    const selectedShipmentMethod = shipmentMethodList.find(e => e.shipmentMethodId === data.shipmentMethodId);

    const calculatedCost = CalculateDiscountedPrice(
        productList.map(p => ({ productId: p.productId, priceGross: p.price, quantity: p.quantity })),
        { priceGross: selectedShipmentMethod?.price ?? 0 },
        discountCodes
    );

    if (dsOrderCreate.state.state === "completed") {
        return (
            <div className="checkout">
                <div className="checkout__title">Zamówienie złożone</div>
                <div className="checkout__description">
                    Zamówienie zostało pomyślnie złożone.
                    <br />
                    <br />
                    <Link to={{ pathname: ROUTES.Order, search: `orderGuid=${dsOrderCreate.state.response.orderGuid}` }}>
                        Przejdź do szczegółów zamówienia i dokonaj płatności.
                    </Link>
                </div>
            </div>
        );
    }

    if (dsOrderCreate.state.state === "pending") {
        return (
            <div className="checkout">
                <div className="checkout__title">Składanie zamówienia...</div>
            </div>
        );
    }

    if (dsOrderCreate.state.state === "error") {
        return (
            <div className="checkout">
                <div className="checkout__title">Błąd :(</div>
                <div className="checkout__description">
                    Wystąpił błąd podczas składania Twojego zamówienia.
                    <br />
                    <br />
                    <b>{dsOrderCreate.state.message}</b>
                    <br />
                    <br />
                    Spróbuj ponownie złożyć zamówienie. Jeśli się to nie powiedzie skontatkuj sie z nami.
                </div>
                <div className="checkout__button">
                    <button
                        className="button"
                        onClick={() => {
                            changeStep(1);
                            dsOrderCreate.reset();
                        }}
                    >
                        Powrót do zamówienia
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="checkout">
            {/* <div className="checkout__shipment-info">Wszystkie zamówienia będą realizowane po 4/01/2021</div> */}

            {data.step === 1 ? (
                <ProductAndShipment
                    products={productList}
                    shipmentMethods={shipmentMethodList}
                    discountCodes={discountCodes}
                    total={calculatedCost.total}
                    totalDiscounted={calculatedCost.totalDiscounted}
                    changeProductQuantityStep={(productId, direction) => changeProductQuantityStep(productId, direction)}
                    addDiscountCode={discountCode => addDiscountCode(discountCode)}
                    removeDiscountCode={discountCodeId => removeDiscountCode(discountCodeId)}
                    setShipment={shipmentMethodId => changeShipmentMethod(shipmentMethodId)}
                    isGoNextDisabled={productTotalCount === 0}
                    goNext={() => changeStep(2)}
                />
            ) : (
                <SummaryProductAndShipment
                    products={productList}
                    shipmentMethod={selectedShipmentMethod}
                    discountCodes={discountCodes}
                    total={calculatedCost.total}
                    totalDiscounted={calculatedCost.totalDiscounted}
                    goBack={() => changeStep(1)}
                />
            )}

            {data.step === 2 ? (
                <CheckoutForm
                    customer={data.customer}
                    address={data.address}
                    customerType={data.customerType}
                    setCustomer={f => setDataPartial(p => ({ customer: f(p.customer) }))}
                    setAddress={f => setDataPartial(p => ({ address: f(p.address) }))}
                    setCustomerType={customerType => setDataPartial(() => ({ customerType: customerType }))}
                    goNext={() => changeStep(3)}
                />
            ) : null}

            {data.step === 3 ? (
                <SummaryCustomerAndAddress
                    customer={data.customer}
                    address={data.address}
                    customerType={data.customerType}
                    goBack={() => changeStep(2)}
                />
            ) : null}

            {data.step === 3 ? <Terms goNext={() => createOrder()} /> : null}
        </div>
    );

    function changeStep(step: 1 | 2 | 3) {
        setDataPartial(() => ({ step: step }));
    }

    function addDiscountCode(discountCode: DiscountCode) {
        // setDataPartial(p => ({ discountCodes: [...p.discountCodes.map(dc => ({ ...dc })), { ...discountCode }] }));
        setDataPartial(prev => {
            if (prev.discountCodes.findIndex(e => e.discountCodeId === discountCode.discountCodeId) !== -1) {
                return {};
            }

            return { discountCodes: [...prev.discountCodes.map(dc => ({ ...dc })), { ...discountCode }] };
        });
    }

    function removeDiscountCode(discountCodeId: number) {
        setDataPartial(prev => {
            const _index = prev.discountCodes.findIndex(e => e.discountCodeId === discountCodeId);

            if (_index === -1) {
                return {};
            }

            const _discountCodes = prev.discountCodes.map(e => ({ ...e }));
            _discountCodes.splice(_index, 1);

            return { discountCodes: _discountCodes };
        });
    }

    function changeShipmentMethod(shipmentMethodId: number) {
        const shipmentMethod = shipmentMethodList.find(e => e.shipmentMethodId === shipmentMethodId);

        if (!shipmentMethod) return;

        if (!shipmentMethod.isActive) return;

        setDataPartial(p => {
            return { shipmentMethodId: shipmentMethodId };
        });
    }

    function changeProductQuantityStep(productId: number, direction: -1 | 1) {
        const product = dsListingProducts.find(e => e.productId === productId);
        if (!product) return;

        setDataPartial(p => {
            const _productList = [...p.productList.map(pr => ({ ...pr }))];
            const _product = _productList.find(e => e.productId === productId);
            if (!_product) {
                if (direction === 1) {
                    _productList.push({ productId: productId, quantity: 1 });
                }
            } else {
                const _quantity = _product.quantity + direction;

                if (_quantity >= 0 && _quantity <= product.stock) {
                    _product.quantity = _quantity;
                }
            }

            return { productList: _productList };
        });
    }

    function setDataPartial(f: (p: CheckoutState) => Partial<CheckoutState>) {
        setData(prev => ({
            ...prev,
            productList: [...prev.productList.map(pr => ({ ...pr }))],
            discountCodes: [...prev.discountCodes.map(dc => ({ ...dc }))],
            customer: { ...prev.customer },
            address: { ...prev.address },
            ...f(prev)
        }));
    }

    function loadListing() {
        dsListing.load({});
    }

    function setShipmentMethod(shipmentMethodId: number) {
        if (dsListing.state.state !== "completed") return;
        const shipmentMethod = dsListing.state.response.shipmentMethods.find(e => e.shipmentMethodId === shipmentMethodId);

        if (!shipmentMethod) return;
        if (!shipmentMethod.isActive) return;

        setData(p => ({ ...p, shipmentMethodId: shipmentMethod.shipmentMethodId }));
    }

    function createOrder() {
        console.log("crete");

        if (!selectedShipmentMethod) return;
        if (productTotalCount <= 0) return;

        dsOrderCreate.load({
            data: {
                address: data.address,
                customer: { ...data.customer, type: data.customerType },
                products: data.productList,
                discountCodeIds: data.discountCodes.map(dc => dc.discountCodeId),
                shipmentMethodId: selectedShipmentMethod.shipmentMethodId,
                totalAmount: calculatedCost.totalDiscounted
            }
        });
    }
};

export default Checkout;
