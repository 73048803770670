import { CheckoutState } from "./Checkout.types";
import { ObjectValidateDefinition } from "@nexcodepl/type-checking/lib/validate.types";

export const checkoutStateValidateDefinition: ObjectValidateDefinition<CheckoutState> = {
    productList: {
        type: "objectArray",
        validate: {
            productId: { type: "number" },
            quantity: { type: "number" }
        }
    },
    discountCodes: {
        type: "objectArray",
        validate: {
            discountCodeId: { type: "number" },
            label: { type: "string" },
            type: { type: "string", in: ["amountShipment", "percentProduct"] },
            amount: { type: "number" },
            priority: { type: "number" }
        }
    },
    shipmentMethodId: { type: "number", optional: true },
    customer: {
        type: "object",
        validate: {
            taxId: { type: "string" },
            name: { type: "string" },
            phone: { type: "string" },
            email: { type: "string" }
        }
    },
    address: {
        type: "object",
        validate: {
            receipient: { type: "string" },
            street: { type: "string" },
            building: { type: "string" },
            zipCode: { type: "string" },
            city: { type: "string" },
            comment: { type: "string" }
        }
    },
    customerType: { type: "number", in: [1, 2] },
    step: {
        type: "number",
        in: [1, 2]
    }
};
