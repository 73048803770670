import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../App";
import { goToStartSection } from "../../utils/GoToStartSection";
import About from "./About";
import Authors from "./Authors";
import Preview from "./Preview";
import Start from "./Start";

const MainPage: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        const fullPath = location.pathname + location.hash;
        switch (fullPath) {
            case ROUTES.Start.About:
                goToStartSection("about");
                break;

            case ROUTES.Start.Authors:
                goToStartSection("authors");
                break;

            case ROUTES.Start.Preview:
                goToStartSection("preview");
                break;

            default:
                window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [location]);

    return (
        <>
            <Start />
            <About />
            <Authors />
            <Preview />
            <div className="przycisk" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
                arrow_drop_up
            </div>
        </>
    );
};

export default MainPage;
