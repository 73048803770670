import React from "react";
import { Link } from "react-router-dom";
import { ROUTER_HOME, ROUTES } from "../../App";
import startimg from "../../assets/image_start.jpg";

const Start: React.FC = () => {
    return (
        <div className="start">
            <div className="start__center">
                <div className="start__left">
                    <div className="start__left__photo">
                        <img src={startimg} className="start__left__photo-image" alt="" />
                    </div>
                </div>
                <div className="start__right">
                    <div className="start__header">Poradnik Inżyniera Elektryka</div>
                    <div className="start__text">
                        Poradnik przeznaczony dla profesjonalistów, którzy projektują, nadzorują lub zajmują się utrzymaniem instalacji
                        elektrycznych. Ponad 580 stron praktycznej wiedzy o nowoczesnych, bezpiecznych i efektywnych rozwiązaniach
                        elektrycznych, w zgodzie z międzynarodowymi normami.
                    </div>
                    <div className="start__text">
                        Już dziś dzięki najnowszej wersji poradnika dowiedz&nbsp;się:
                        <b>
                            <br />
                            "Które rozwiązanie techniczne zagwarantuje spełnienie obowiązujących zasad bezpieczeństwa?"
                        </b>
                    </div>
                    <Link to={ROUTES.Checkout} className="button secondary">
                        Kup teraz <i className="start__buttons-icon">shopping_cart</i>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Start;
