import { useState, useEffect } from "react";
import { FieldBaseProps } from "./FieldBase";
import { FieldWrapperProps } from "./FieldWrapper";

export type FormObject<T> = {
    [K in keyof T]: string | boolean;
};

export interface FormError<T extends FormObject<T>> {
    fieldName: keyof T;
    id: string;
    error: string;
}

export interface FormValidateResult<T extends FormObject<T>> {
    errors: Array<FormError<T>>;
}

export type FormValidateFunction<TForm extends FormObject<TForm>> = (
    form: TForm,
    fieldName?: keyof TForm,
    isEditing?: boolean,
    customProps?: any
) => Promise<FormValidateResult<TForm>>;

type ValidatingFields<T extends FormObject<T>> = {
    [K in keyof T]: boolean;
};

export function useForm<TForm extends FormObject<TForm>>(
    form: TForm,
    setForm: (f: (previous: TForm) => TForm) => void,
    validateFunction?: FormValidateFunction<TForm>,
    errorProxy?: (error: FormError<TForm>) => string,
    isFormDisabled?: boolean,
    customProps?: { [key: string]: any }
) {
    // const [form, setForm] = useState<TForm>(object);
    const [errors, setErrors] = useState<Array<FormError<TForm>>>([]);
    const [focusedField, setFocusedField] = useState<keyof TForm | undefined>(undefined);
    const [lastFocusedField, setLastFocusedField] = useState<keyof TForm | undefined>(undefined);
    const [isValidating, setIsValidating] = useState<boolean>(false);
    const [validatingFields, setValidatingFields] = useState<ValidatingFields<TForm>>(initialObjectToValidationFields(form));
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    // validate field on blur
    useEffect(() => {
        if (lastFocusedField && focusedField === undefined) {
            validateField(lastFocusedField);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastFocusedField, focusedField]);

    // remove invalid errors while field editing
    useEffect(() => {
        if (focusedField) {
            validateField(focusedField, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    return {
        form,
        errors,
        setForm,
        fieldProps,
        validateForm,
        validateField,
        setIsDisabled
    };

    function fieldProps<TFieldName extends keyof TForm>(fieldName: TFieldName): FieldBaseProps<TForm[TFieldName]> & FieldWrapperProps {
        return {
            value: form[fieldName],
            onChange: changeFunction => {
                setForm(previous => ({ ...previous, [fieldName]: changeFunction(previous[fieldName]) }));
            },
            errors: errors.filter(e => e.fieldName === fieldName).map(e => (errorProxy ? errorProxy(e) : e.error)),
            fieldBlur: () => {
                setFocusedField(currentField => (currentField === fieldName ? undefined : currentField));
                setLastFocusedField(fieldName);
            },
            fieldFocus: () => setFocusedField(fieldName),
            disabled: isFormDisabled || isValidating || validatingFields[fieldName] || isDisabled
        };
    }

    async function validateForm(_customProps?: any) {
        setIsValidating(true);
        const errors = validateFunction ? (await validateFunction(form, undefined, false, { ...customProps, ..._customProps })).errors : [];
        setErrors(errors);
        setIsValidating(false);
        return errors.length === 0;
    }

    async function validateField(fieldName: keyof TForm, reduceMode?: boolean, _customProps?: any) {
        if (!reduceMode) setValidatingField(fieldName, true);
        const errors = validateFunction
            ? (await validateFunction(form, fieldName, reduceMode, { ...customProps, ..._customProps })).errors
            : [];
        setValidatingField(fieldName, false);

        const errorsForField = errors.filter(e => e.fieldName === fieldName);

        setErrors(currentErrors => {
            return reduceMode
                ? [...currentErrors].filter(e => e.fieldName !== fieldName || errorsForField.findIndex(ef => ef.id === e.id) !== -1)
                : [...[...currentErrors].filter(e => e.fieldName !== fieldName), ...errorsForField];
        });

        return errorsForField.length === 0;
    }

    function setValidatingField(fieldName: keyof TForm, state: boolean) {
        setValidatingFields(p => ({ ...p, [fieldName]: state }));
    }
}

export function fieldNameIs<T extends FormObject<T>>(fieldName: keyof T | undefined, currentFieldName?: keyof T): boolean {
    return fieldName === undefined || fieldName === currentFieldName;
}

function initialObjectToValidationFields<TForm extends FormObject<TForm>>(initialObject: TForm): ValidatingFields<TForm> {
    return (Object.keys(initialObject) as Array<keyof TForm>).reduce((validatingObject, key) => {
        validatingObject[key] = false;
        return validatingObject;
    }, {} as ValidatingFields<TForm>);
}
