import React from "react";

import "./TextSwitch.scss";

interface TextSwitchOption {
    id: number;
    label: string;
}

interface TextSwitchProps {
    options: TextSwitchOption[];
    value: number;
    setValue: (value: number) => void;
}

const TextSwitch: React.FC<TextSwitchProps> = ({ options, value, setValue }) => {
    return (
        <div className="text-switch">
            {options.map(option => (
                <div
                    className={`text-switch__option ${option.id === value ? "is-selected" : ""}`}
                    key={option.id}
                    onClick={() => setValue(option.id)}
                >
                    {option.label}
                </div>
            ))}
        </div>
    );
};

export default TextSwitch;
