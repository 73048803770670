import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ROUTER_HOME, ROUTES } from "../App";
import logo from "../assets/LIO-Logos-SE.png";

const Nav: React.FC = () => {
    const [isOpen, isOpenChange] = useState(false);

    function changeState() {
        isOpenChange(p => !p);
    }

    function closeNav() {
        isOpenChange(false);
    }

    return (
        <div className={`nav ${isOpen ? "is-open" : ""}`}>
            <div className="nav__center">
                <NavLink to={ROUTER_HOME.MainPage} className="nav__logo" onClick={() => closeNav()}>
                    <img src={logo} className="nav__logo-image" alt="Schneider Electric" />
                </NavLink>
                <div className="nav__right">
                    <div className="nav__right-button" onClick={() => changeState()}>
                        {isOpen ? "close" : "notes"}
                    </div>
                </div>
                <div className="nav__links">
                    <div className="nav__links-left">
                        <NavLink to={ROUTES.Start.About} className="nav__link" onClick={() => closeNav()}>
                            O Poradniku
                        </NavLink>
                        <NavLink to={ROUTES.Start.Authors} className="nav__link" onClick={() => closeNav()}>
                            O Autorach
                        </NavLink>
                        <NavLink to={ROUTES.Start.Preview} className="nav__link" onClick={() => closeNav()}>
                            Podgląd
                        </NavLink>
                        <div className="nav__link">
                            <NavLink to={ROUTES.Checkout} className="button primary" onClick={() => closeNav()}>
                                Zakup Poradnik
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Nav;
