import React from "react";

const Authors: React.FC = () => {
    return (
        <div className="authors" id="authors">
            <div className="authors__center">
                <div className="authors__wrapper">
                    <div className="authors__header">O autorach</div>
                    <div className="authors__text">
                        Autorzy Poradnika to eksperci Schneider Electric z dziedziny dystrybucji zasilania elektrycznego, którzy dzielą się
                        swoim doświadczeniem oraz wiedzą o nowych i zaktualizowanych normach technicznych, a także rozwoju elektrotechniki.
                    </div>
                    <div className="authors__cursive">
                        "Celem niniejszego poradnika jest zapewnienie jasnego, praktycznego i pełnego badania instalacji elektrycznej
                        krok&nbsp;po&nbsp;kroku"
                    </div>
                    <div className="authors__author">Zespół Redakcyjny Schneider Electric</div>
                </div>
                <div className="about__section-bottomline"></div>
            </div>
        </div>
    );
};

export default Authors;
