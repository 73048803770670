import { Endpoint } from "@schneider-ecommerce/client-endpoint";
import React, { useEffect } from "react";
import { useDatasource } from "../../hooks/useDatasource";
import { useSearchParam } from "../../hooks/useSearchParam";

import SummaryProductAndShipment from "../Checkout/SummaryProductAndShipment";
import SummaryCustomerAndAddress from "../Checkout/SummaryCustomerAndAddress";

import "./Order.scss";
import { OrderStatus, PaymentStatus } from "@schneider-ecommerce/utils-generic";
import moment from "moment";

const Order: React.FC = () => {
    const orderGuid = useSearchParam("orderGuid", "string");

    const dsOrderDetails = useDatasource(Endpoint.Order.GetDetails);
    const dsPaymentCreate = useDatasource(Endpoint.Payment.PostCreate);

    useEffect(() => {
        loadOrderDetails();

        return () => dsOrderDetails.cancel();
    }, [orderGuid]);

    useEffect(() => {
        if (dsPaymentCreate.state.state === "completed") {
            window.location.href = dsPaymentCreate.state.response.paymentLink;
        }
    }, [dsPaymentCreate.state]);

    if (dsOrderDetails.state.state === "idle") {
        return (
            <div className="order">
                <div className="order__title">Brak identyfikatora zamówienia</div>
                <div className="order__description">
                    W adresie strony nie mogliśmy znaleźć identyfikatora zamówienia.
                    <br />
                    Prawdopodobnie kliknięto w uszkodzony link.
                </div>
            </div>
        );
    }

    if (dsOrderDetails.state.state === "pending") {
        return (
            <div className="order">
                <div className="order__title">Ładowanie zamówienia...</div>
            </div>
        );
    }

    if (dsOrderDetails.state.state === "error") {
        return (
            <div className="order">
                <div className="order__title">Błąd ładowania zamówienia</div>
                <div className="order__description">
                    Wystąpił błąd podczas ładowania zamówienia numer: {orderGuid || ""}
                    <br />
                    <br />
                    {dsOrderDetails.state.message}
                </div>
            </div>
        );
    }

    if (dsPaymentCreate.state.state === "pending") {
        return (
            <div className="order">
                <div className="order__title">Generowanie linku do płatności...</div>
            </div>
        );
    }

    if (dsPaymentCreate.state.state === "completed") {
        return (
            <div className="order">
                <div className="order__title">Wygenerowano link do płatności</div>
                <div className="order__description">
                    Kliknij w link poniżej aby przejść do płatności.
                    <br />
                    <br />
                    <a href={dsPaymentCreate.state.response.paymentLink}>{dsPaymentCreate.state.response.paymentLink}</a>
                </div>
            </div>
        );
    }

    if (dsPaymentCreate.state.state === "error") {
        return (
            <div className="order">
                <div className="order__title">Błąd :(</div>
                <div className="order__description">
                    Wystąpił błąd podczas generowania linku do płatności.
                    <br />
                    <br />
                    {dsPaymentCreate.state.message}
                    <br />
                    <br />
                    Spróbuj ponownie.
                    <br />
                    <br />
                    <button className="button" onClick={() => dsPaymentCreate.reset()}>
                        Powrót
                    </button>
                </div>
            </div>
        );
    }

    if (dsOrderDetails.state.state === "completed") {
        const o = dsOrderDetails.state.response;
        const statusNumber = paymentStatusToNumber(o.order.status);
        return (
            <div className="order">
                <div className="order__title">
                    Zamówienie
                    <br />
                    <span className="smaller">{orderGuid}</span>
                </div>
                <div className="order__status">Status: {statusToPl(o.order.status)}</div>
                <div className="order__state">
                    <div className="order__state-container">
                        <div className={`order__state-container__cell ${statusNumber >= 1 ? 'is-ok' : ''}`}>
                            <div className="order__state-container__cell-dot"></div>
                            <div className="order__state-container__cell-text">Do zapłaty</div>
                        </div>
                        <div className={`order__state-container__cell ${statusNumber >= 2 ? 'is-ok' : ''}`}>
                            <div className="order__state-container__cell-dot"></div>
                            <div className="order__state-container__cell-text"> {o.order.status === 'paymentVerification' ? 'Weryfikacja płatności' : 'Zamówienie opłacone'}</div>
                        </div>
                        <div className={`order__state-container__cell ${statusNumber >= 3 ? 'is-ok' : ''}`}>
                            <div className="order__state-container__cell-dot"></div>
                            <div className="order__state-container__cell-text">Przygotowane do wysłania</div>
                        </div>
                        <div className={`order__state-container__cell ${statusNumber >= 4 ? 'is-ok' : ''}`}>
                            <div className="order__state-container__cell-dot"></div>
                            <div className="order__state-container__cell-text">W trakcie realizacji</div>
                        </div>
                        <div className={`order__state-container__cell ${statusNumber >= 5 ? 'is-ok' : ''}`}>
                            <div className="order__state-container__cell-dot"></div>
                            <div className="order__state-container__cell-text">Dostarczone</div>
                        </div>
                        <div className={`order__state-container__cell ${statusNumber >= 6 ? 'is-ok' : ''}`}>
                            <div className="order__state-container__cell-dot"></div>
                            <div className="order__state-container__cell-text">Wysłano</div>
                        </div>
                    </div>
                </div>
                <SummaryProductAndShipment
                    products={o.items.map(i => ({ ...i, stock: 0 }))}
                    shipmentMethod={{
                        shipmentMethodId: o.shipmentMethod.shipmentMethodId,
                        label: o.shipmentMethod.label,
                        price: o.order.amountShipment,
                        priceDiscounted: o.order.amountShipmentDiscounted,
                        isSelected: true,
                        isActive: true
                    }}
                    discountCodes={o.discountCodesUsed}
                    total={o.order.amountProducts + o.order.amountShipment}
                    totalDiscounted={o.order.amountTotal}
                />
                <div className="order__subtitle">Płatności</div>
                <div className="order__payments">
                    <table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {o.payments.map(payment => (
                                <tr key={payment.paymentGuid}>
                                    <td>{moment(payment.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                    <td>{paymentStatusToPl(payment.status)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {o.payments.length === 0 ? (
                        <div className="order__payments-empty">
                            Brak płatności.
                            <br />
                            Zapłać klikając przycisk poniżej.
                        </div>
                    ) : null}
                    {o.order.status === "new" ? (
                        <div className="order__payment-button">
                            <button className="button" onClick={() => createPayment()}>
                                Płacę z PayU
                            </button>
                        </div>
                    ) : null}
                </div>
                <SummaryCustomerAndAddress
                    customer={{ ...o.order.customer, phone: "", taxId: o.order.customer.taxId || "" }}
                    address={{ ...o.order.address }}
                    customerType={o.order.customer.type}
                />
            </div>
        );
    }

    return (
        <div className="order">
            <div className="order__title">Błąd ładowania zamówienia</div>
            <div className="order__description">Wystąpił nieznany błąd.</div>
        </div>
    );

    function loadOrderDetails() {
        if (orderGuid) {
            dsOrderDetails.load({ params: { orderGuid: orderGuid } });
        }
    }

    function createPayment() {
        if (orderGuid) {
            dsPaymentCreate.load({ data: { orderGuid: orderGuid } });
        }
    }
};

export default Order;

function statusToPl(status: OrderStatus) {
    switch (status) {
        case "new":
            return "Do zapłaty";

        case "paid":
            return "Zamówienie opłacone";

        case "paymentVerification":
            return "Weryfikacja płatności";

        case "prepareForShipment":
            return "Przygotowane do wysłania";

        case "processing":
            return "W trakcie realizacji";

        case "received":
            return "Dostarczone";

        case "shipment":
            return "Wysłano";
    }
}

function paymentStatusToPl(status: PaymentStatus) {
    switch (status) {
        case "new":
            return "Nowa płatność";

        case "pending":
            return "Przetwarzanie płatności";

        case "completed":
            return "Płatność zaakceptowana";

        case "canceled":
            return "Płatność odrzucona";
    }
}
function paymentStatusToNumber(status: OrderStatus) {
    switch (status) {
        case "new":
            return 1;
        case "paymentVerification":
        case "paid":
            return 2;

        case "prepareForShipment":
            return 3;
        case "processing":
            return 4;
        case "received":
            return 5;
        case "shipment":
            return 6;
        default:
            return 0;
    }
}
